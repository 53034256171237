import { Switch, Route } from 'react-router-dom'
import SignIn from './signin'
import Consent from './consent'

const Routes = () => {
  return (
    <>
      <Switch>
        <Route path="/oauth2/consent">
          <Consent
            clientName="CGN"
            clientDescription="CGN is an integrated college and career solution that leads students and families through the entire college and career planning process."
            infoLink="https://www.collegeguidancenetwork.com/"
          />
        </Route>
        <Route path="/oauth2/signin">
          <SignIn />
        </Route>
      </Switch>
    </>
  )
}

export default Routes
